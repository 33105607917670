import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
} from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"


const PrivacyPage = () => (
  <Layout backButton={true} pageInfo={{ pageName: "privacy" }}>
    <Seo title="Политика за лични данни" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <Breadcrumb.Item active>ПОЛИТИКА ЗА ЛИЧНИ ДАННИ</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="mb-5 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Защита на личните данни
          </h1>
        </Col>
        <Col sm={12} className="order-md-0">
          <p>
            На 25 май 2018 година в сила влезе Регламент (ЕС) 2016/679 на
            Европейския парламент и Европейския съвет за защитата на физическите
            лица с акцент обработването на лични данни и относно свободно
            движение на такива данни и за отмяната на Директива 95/46/ЕО. В тази
            връзка искаме да Ви информираме, че Вита Херб ИНТ ЕООД с ЕИК
            175150004 и адрес гр. София 1113, бул. “Шипченски проход” № 18, ет.
            8, офис 802, прилага всички възможни начини, за да защити личните
            данни на своите клиенти. По-долу ще откриете информация за това как
            и защо използваме Вашите лични данни, какви лични данни използваме,
            както и какви са Вашите права, позовавайки се на Регламент (ЕС)
            2016/679.
          </p>
          <p>
            Лични данни са всички данни, чрез които клиентът може да бъде
            идентифициран пряко или непряко. Пример за лични данни са: три
            имена, ЕГН, телефонен номер, адрес на местожителство, имейл адрес и
            други.
          </p>
          <p>
            Вита Херб ИНТ ЕООД използва Вашите лични данни, за да обработи и
            изпълни постъпили поръчки и свързани с това дейности като издаване и
            обработка на фактури и управление на взаимоотношенията с клиенти.
            Като предоставите личните си данни, ни давате възможността:
          </p>
          <ul>
            <li>Да получим и обработим Вашата поръчка;</li>
            <li>Да доставим желания от Вас продукт до желания от Вас адрес;</li>
            <li>Да Ви информираме за статуса на поръчката;</li>
            <li>Да Ви предоставяме подходящи маркетингови съобщения;</li>
            <li>
              Да изпълним законноустановените счетоводни и данъчни изисквания.
            </li>
          </ul>
          <p>Използваме следните типове данни, обединени в две групи:</p>
          <ul>
            <li>
              Лични данни, предоставени от Вас при поръчка на продукт. Тези
              данни включват три имена, телефонен номер, адрес за доставка,
              имейл адрес, номер на банковата сметка в случай, че заплатите
              нашите продукти чрез банкова транзакция.
            </li>
            <li>
              Лични данни, събрани посредством бисквитката (cookies) в нашия
              сайт. Тези данни включват държавата и града, в които се намирате,
              вашия пол, тип устройство, което използвате. Тези данни са
              обобщени заедно с данните на останалите посетители на сайта ни и
              чрез тях не можете да бъдете персонално идентифицирани.
            </li>
          </ul>
          <p>
            Позовавайки се на Регламент (ЕС) 2016/679, Вие имате правото да:
          </p>
          <ul>
            <li>Поправяте и/или актуализирате Вашите лични данни;</li>
            <li>
              Поискате информация за това какви Ваши лични данни обработваме и
              съхраняваме;
            </li>
            <li>
              Ограничите обработката на Вашите лични данни от трети страни.
            </li>
            <li>
              Откажете получаването на маркетингови съобщения от Вита Херб ИНТ
              ЕООД;
            </li>
            <li>
              Поискате прекратяване на използването и заличаване на Ваши лични
              данни.
            </li>
          </ul>
          <p>
            Вита Херб ИНТ ЕООД съхранява личните Ви данни в допустимите от
            закона срокове. В случай, че смятате, че данните Ви са неправилно
            или нерегламентирано обработвани, молим да се свържете с нас на
            office@vitaherb.bg. Ако смятате, че искането Ви не е изпълнено в
            разумен срок можете да се обърнете към КЗЛД.
          </p>
          <p>
            Вита Херб ИНТ ЕООД не използва данни от трети страни. Вита Херб ИНТ
            ЕООД предоставя данни на трети страни само и единствено въз основа
            на договорни споразумения и ангажимент за поверителност от тяхна
            страна. Трети страни, с които Вита Херб ИНТ ЕООД работи, са
            доставчици на куриерски услуги, доставчици на счетоводни услуги,
            доставчици на маркетингови, банкови и хостинг услуги.
          </p>
          <p>
            Можете да се свържете с нас всеки делничен ден от 09:00 ч. до 18:00
            ч. по посочените начини: Email: office@vitaherb.bg, телефонен номер:
            02 944 60 06.
          </p>
        </Col>
      </Row>

    </Container>
  </Layout>
)

export default PrivacyPage
